<div class="content-header" *ngIf="getSelectedView as selectedViewItem">
  <div class="title heading-1">{{selectedViewItem.header | titlecase}}</div>
  <div class="description">{{selectedViewItem.info}}</div>
  <div class="content-background"></div>
</div>
<div #services class="content-with-sidebar">
  <div class="sidebar">
    <mat-card>
      <mat-accordion>
        <mat-expansion-panel class="sidebar-menu" *ngFor="let service of serviceList"
                             [expanded]="selectedView === service.header" (afterExpand)="selectedView = service.header" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="header heading-3 bold">
              <fa-icon class="right-spacer" *ngIf="service.icon" [icon]="service.icon" [fixedWidth]="true"></fa-icon>
              {{service.header | uppercase}}
            </mat-panel-title>
            <mat-panel-description>
              <fa-icon [icon]="Icons.faChevronDown" [fixedWidth]="true"></fa-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let subHeader of service.subHeaders">
            <div class="subtitle heading-4">{{subHeader.title}}</div>
            <div class="detail" *ngFor="let detail of subHeader.details">
              {{detail}}
            </div>
          </ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>
  <div class="content" *ngIf="getSelectedView as selectedViewItem">
    <span [innerHTML]="selectedViewItem.content"></span>
  </div>
</div>
<app-break [layout]="'long-to-short'"></app-break>
<div class="floating-header">
  <div class="heading-1">Galerii</div>
</div>
<swiper #works [spaceBetween]="12" [slidesPerView]="1" [navigation]="true">
  <ng-template swiperSlide *ngFor="let image of imageList; let index = index">
    <img [src]="image.path">
    <div class="image-description">{{image.description || '&nbsp;'}}</div>
  </ng-template>
</swiper>
<app-break [layout]="'short-to-long'" [color]="'grey-to-color'"></app-break>
<div #contact class="content-with-sidebar flex-reverse-mobile">
  <div class="content">
    <div class="heading-3">Kontaktvorm</div>
    <div class="flex flex-column" [formGroup]="serviceForm">
      <mat-form-field appearance="outline" class="max-width no-hint">
        <mat-label>Nimi</mat-label>
        <input matInput [formControlName]="'nameControl'">
      </mat-form-field>
      <div class="flex flex-column-mobile">
        <mat-form-field appearance="outline" class="max-width no-hint">
          <mat-label>E-posti aadress</mat-label>
          <input matInput [formControlName]="'emailControl'">
          <fa-icon matSuffix [icon]="Icons.faAt"></fa-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="max-width no-hint">
          <mat-label>Telefon</mat-label>
          <input matInput [formControlName]="'phoneControl'">
          <fa-icon matSuffix [icon]="Icons.faPhone"></fa-icon>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="max-width no-hint">
        <mat-label>Kirjeldus</mat-label>
        <textarea matInput [cdkTextareaAutosize]="true" [cdkAutosizeMinRows]="8" [formControlName]="'description'"></textarea>
      </mat-form-field>
      <div class="align-right">
        <button mat-flat-button color="primary" [disabled]="!serviceForm.valid" (click)="submitForm()">Submit</button>
      </div>
    </div>
  </div>
  <div class="sidebar no-inset">
    <mat-card>
      <mat-accordion>
        <mat-expansion-panel class="sidebar-menu" [expanded]="true" hideToggle disabled>
          <mat-expansion-panel-header>
            <mat-panel-title class="header heading-3 bold">
              <fa-icon class="right-spacer" [icon]="Icons.faAt" [fixedWidth]="true"></fa-icon>
              {{'Võta ühendust' | uppercase}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="subtitle heading-4">
            {{'Küsi pakkumist e-posti aadressilt'}}
            <span class="color-primary">{{'info@gmb.ee'}}</span>
          </div>
          <div class="detail">
            <fa-icon [icon]="Icons.faArrowLeft" class="right-spacer desktop"></fa-icon>
            <fa-icon [icon]="Icons.faArrowDown" class="right-spacer mobile mobile-inline"></fa-icon>
            {{'Või kasuta kontaktvormi'}}
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>
</div>

