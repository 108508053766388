import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MaterialModule} from './material.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LayoutComponent} from './layout/layout.component';
import {ServicesComponent} from './layout/views/services/services.component';
import {IvyGalleryModule} from 'angular-gallery';
import {SwiperModule} from 'swiper/angular';
import {BreakComponent} from './components/break/break.component';
import {HttpClientModule} from '@angular/common/http';
import {ContactService} from './services/contact.service';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ServicesComponent,
    BreakComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IvyGalleryModule,
    SwiperModule,
    HttpClientModule
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
