export interface MenuItem {
  route: string;
  name: string;
}
export interface PrimaryMenuItem extends MenuItem {
  items: Array<MenuItem>;
}

export const MenuItems: PrimaryMenuItem[] = [
  {
    route: 'services',
    name: 'Teenused',
    items: []
  }
];
