import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-break',
  templateUrl: './break.component.html',
  styleUrls: ['./break.component.scss']
})
export class BreakComponent implements OnInit {
  @Input()
  layout: string;
  @Input()
  color = 'color-to-grey';

  constructor() { }

  ngOnInit(): void {
  }

}
