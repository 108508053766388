import {Component, OnInit} from '@angular/core';
import {MenuItems} from './menu-items';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit {
  private currentPath = '';
  public MenuItems = MenuItems;
  Icons = fas;

  constructor(
    private router: Router
  ) {
    router.events.subscribe((route: any) => {
      if (route.url !== undefined && route.url !== '/' && route.url !== '/app') {
        this.currentPath = route.url;
      }
    });
    router.onSameUrlNavigation = 'reload';
  }
  ngOnInit(): void {}
  onActivate(inEvent: any, outlet: HTMLElement): void {
    outlet.scrollTop = 0;
  }
  isActiveMenu(inRoute: string): boolean {
    return this.router.url.split( '/' )[2] === inRoute;
  }
}
