import { Component, OnInit } from '@angular/core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../../services/contact.service';
SwiperCore.use([Pagination, Navigation]);

interface ToolsService {
  title: string;
  description: string;
  content: string;
}

interface ServiceItem {
  header: string;
  info: string;
  icon?: IconProp;
  subHeaders: ServiceHeaders[];
  content: string;
}
interface ServiceHeaders {
  title: string;
  details: string[];
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html'
})
export class ServicesComponent implements OnInit {
  Icons = fas;
  selectedView = 'ehitus';
  serviceList: Array<ServiceItem> = [
    {
      header: 'ehitus',
      info: 'Hooned, Aiad ning Haljastustööd',
      icon: this.Icons.faHome,
      subHeaders: [
        {
          title: 'Hoonete ehitus ja remont',
          details: [
            'Korterid',
            'Eramud',
            'Abihooned'
          ]
        },
        {
          title: 'Aedade ehitus',
          details: [
            'Aiad',
            'Kõrgteed',
            'Äärekivid'
          ]
        },
        {
          title: 'Haljastustööd',
          details: [
            'Haljastamine',
            'Sisehoovid'
          ]
        }
      ],
      content: '<div class="heading-2">Meist</div>' +
        'Oleme <span class="heading-5">Üldehituse</span>, <span class="heading-5">Soojustamise</span>, <span class="heading-5">Haljastustööde</span>, ' +
        '<span class="heading-5">Aiaehituse</span> ja <span class="heading-5">Kinnisvara haldusega</span> tegelev ettevõte ning lisaks pakume ka' +
        'tööriistade ja ehitustehnika renditeenust.' +
        '<br>Meie spetsialistid omavad oma valdkonnas pikaajalist kogemust ning peamiselt teostame töid <span class="heading-5">Saaremaal</span>.'
    },
    {
      header: 'rentimine',
      info: 'Mehaanika ning Tööriistade rent',
      icon: this.Icons.faCommentsDollar,
      subHeaders: [
        {
          title: 'Mehaanika rent',
          details: [
            'Multione kopplaadur + tarvikud',
            'Treilerid',
          ]
        },
        {
          title: 'Tööriistade rent ja remont',
          details: [
            'Elektritööriistad',
            'Käsitööriistad'
          ]
        }
      ],
      content: '<div class="heading-2">MultiOne Kopplaadur ja tarvikud</div>' +
        'Saadaval on <span class="heading-5">MultiOne 8 seeria mini-kopplaadur</span> koos tarvikutega. Laadurit on võimalik rentida nii juhiga kui ilma. ' +
        'Multione kopplaaduri kohta saab täpsemat lugeda <a href="http://multione.ee/8_seeria">siit</a>. Saadaval olevate tarvikute kohta saab lähemalt ' +
        'küsida võttes meiega ühendust.<br>' +
        '<div class="heading-2">Tööriistade rent ja remont</div>' +
        'Teostame nii elektri- kui käsitööriistade renti ning remonti. Täpsema nimekirja saadaval olevatest tööriistadest saab ühendust võttes.'
    },
    {
      header: 'transport',
      info: 'Transpordivahendid',
      icon: this.Icons.faTruck,
      subHeaders: [
        {
          title: 'Kaubik',
          details: []
        },
        {
          title: 'Tõstuk-kallur',
          details: []
        },
        {
          title: 'Muud vahendid',
          details: [
            'vastavalt vajadusele'
          ]
        }
      ],
      content: '<div class="heading-2">Transpordivahendid</div>' +
        'Pakume transpordivõimalust ning rentimiseks <span class="heading-5">Kaubikut</span> ning <span class="heading-5">Tõstuk-kallurit</span>. Soovik korral ' +
        'võime leida ka teie vajadusele vastavaid muid transpordivahendeid (järelkärud jms.)'
    }
  ];

  imageList: any[] = [
    {
      path: 'assets/images/20230619_125720.jpg',
      description: 'MultiOne kopplaadur'
    },
    {
      path: 'assets/images/20230717_181912.jpg',
      description: 'MultiOne kopplaadur'
    },
    {
      path: 'assets/images/20230701_124832.jpg'
    },
    {
      path: 'assets/images/20230701_124842.jpg'
    },
    {
      path: 'assets/images/20230719_132324.jpg'
    },
    {
      path: 'assets/images/20230719_101734.jpg'
    },
    {
      path: 'assets/images/20230719_101556.jpg'
    },
    {
      path: 'assets/images/20230719_101441.jpg'
    },
    {
      path: 'assets/images/Kaubiku kujundus 2.jpg'
    }
  ];

  serviceForm = new UntypedFormGroup({
    nameControl: new UntypedFormControl(''),
    emailControl: new UntypedFormControl('', [Validators.email]),
    phoneControl: new UntypedFormControl(''),
    description: new UntypedFormControl('', [Validators.required])
  });

  constructor(private contactService: ContactService) { }

  ngOnInit(): void {
  }

  get getSelectedView(): ServiceItem {
    return this.serviceList.find(service => service.header === this.selectedView);
  }

  submitForm(): void {
    this.contactService.PostMessage({
      Nimi: this.serviceForm.value.nameControl,
      Telefon: this.serviceForm.value.phoneControl,
      EPost: this.serviceForm.value.emailControl,
      Sisu: this.serviceForm.value.description
    }).subscribe(_ => {
      location.href = 'https://mailthis.to/confirm';
    });
  }

}
