<div class="layout-content">
  <div class="page-header">
    <div class="primary-row">
      <img src="assets/gmb_logo_red.svg" class="logo color-primary" [routerLink]="'app'"><br>
    </div>
    <div class="navigation-row">
      <div class="navigation-list">
        <button mat-button class="navigation-item">&nbsp;</button>
      </div>
    </div>
  </div>
  <div class="page-content">
    <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
  </div>
  <div class="page-footer">
    <div class="footer-content flex space-between">
      <div class="footer-left flex">
        <div>
          <div class="heading-5">&nbsp;</div>
        </div>
      </div>
      <div class="footer-right">
        <img src="assets/gmb_logo_red.svg" class="logo color-primary" [routerLink]="'app'"><br>
        <img src="assets/ehitus_white.svg" class="logo" [routerLink]="'app'">
      </div>
    </div>
  </div>
</div>
