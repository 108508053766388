import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private api = 'https://MailThis.to/info@gmb.ee';
  // private api = 'https://MailThis.to/januskar15@hotmail.com';

  constructor(private http: HttpClient) { }

  PostMessage(input: any): Observable<string> {
    return this.http.post(this.api, input, { responseType: 'text' }).pipe(
      map(
        (response) => {
          if (response) {
            return response;
          }
          return null;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }
}
